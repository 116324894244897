import {
  ChangeDetectorRef,
  Directive,
  inject,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { FeatureType, PolicyService } from './policy.service';

export interface HasFeatureContext {
  feature: keyof FeatureType;
  negate: boolean;
}
@Directive({
  selector: '[flxHasFeature]',
})
export class HasFeatureDirective implements OnInit, OnDestroy {
  private readonly templateRef = inject(TemplateRef<any>);
  private readonly viewContainer = inject(ViewContainerRef);
  private readonly policy = inject(PolicyService);
  private readonly cdr = inject(ChangeDetectorRef);

  @Input() flxHasFeature!: keyof FeatureType | HasFeatureContext;
  subscriptions: Subscription[] = [];

  get featureQuery() {
    return typeof this.flxHasFeature === 'string' ? this.flxHasFeature : this.flxHasFeature.feature;
  }

  get negated() {
    return typeof this.flxHasFeature === 'string' ? false : this.flxHasFeature.negate;
  }

  isRendered = false;

  ngOnInit() {
    this.viewContainer.clear();
    this.subscriptions.push(this.policy.featureChanged(this.featureQuery).subscribe(() => this.processTemplate()));
    this.processTemplate();
  }

  async processTemplate() {
    const hasFeature = await this.policy.hasFeatureAsync(this.featureQuery);
    if ((hasFeature && this.negated === false) || (!hasFeature && this.negated === true)) {
      if (!this.isRendered) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.cdr.markForCheck();
        this.isRendered = true;
      }
    } else if (this.isRendered) {
      this.viewContainer.clear();
      this.isRendered = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
